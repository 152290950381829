import {
  Edit,
  Link as LinkIcon,
  Close,
  AutoFixHigh,
} from '@mui/icons-material';
import {
  Paper,
  Grid,
  Stack,
  Typography,
  IconButton,
  Button,
  Tooltip,
  Snackbar,
  Divider,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useTotalDownloadsQuery from '../../../hooks/useTotalDownloadsQuery';
import EpisodeImage from '../../EpisodeImage';
import Markdown from '../../Markdown';
import ItemChips from './ItemChips';

const Item = ({ episodeId, item }) => {
  const navigate = useNavigate();
  const { title, subtitle, description, url } = item;
  const [t] = useTranslation();
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const { podcastId } = useParams();
  const { data, isLoading, isSuccess } = useTotalDownloadsQuery(podcastId);

  const getDownloads = () => {
    if (!isSuccess) {
      return 0;
    }

    const episode = data.find((episode) => episode.episodeId === episodeId);
    return episode ? episode.num : 0;
  };

  const handleCloseSuccessMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessMessageVisible(false);
  };

  return (
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
      <Stack spacing={0} mb={2}>
        <ItemChips item={item} />
        <Typography variant="h4">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <EpisodeImage
              {...item}
              onClick={() => navigate(`${episodeId}/edit`)}
            />
            <Tooltip title={t('Item.editEpisodeTooltip')}>
              <Button
                startIcon={<Edit />}
                aria-label={t('Item.editEpisode')}
                onClick={() => navigate(`${episodeId}/edit`)}
                size="large"
              >
                {t('Item.editEpisode')}
              </Button>
            </Tooltip>
            <Tooltip title={t('Item.assistant.tooltip')}>
              <Button
                startIcon={<AutoFixHigh />}
                aria-label={t('Item.assistant.label')}
                onClick={() => navigate(`${episodeId}/assistant`)}
                size="large"
              >
                {t('Item.assistant.label')}
              </Button>
            </Tooltip>
            {url && (
              <>
                <CopyToClipboard text={url} onCopy={setSuccessMessageVisible}>
                  <Tooltip title={t('Item.copyEpisodeUrl.toolTip')}>
                    <Button
                      startIcon={<LinkIcon />}
                      aria-label={t('Item.copyEpisodeUrl.toolTip')}
                      size="large"
                    >
                      {t('Item.copyEpisodeUrl')}
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  open={!!successMessageVisible}
                  autoHideDuration={6000}
                  onClose={handleCloseSuccessMessage}
                  message={t('Item.copyEpisodeUrl.success')}
                  action={
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseSuccessMessage}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  }
                />
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Markdown text={description} truncateLength={600} />
        </Grid>
      </Grid>
      {item.date.toDate() < Date.now() && (
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {isLoading
              ? t('Item.stats.loading')
              : t('Item.stats.label', { downloads: getDownloads() })}
          </Typography>
        </Stack>
      )}
    </Paper>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    processing: PropTypes.string,
    date: PropTypes.instanceOf(Timestamp).isRequired,
    url: PropTypes.string,
  }).isRequired,
  episodeId: PropTypes.string.isRequired,
};

export default Item;
